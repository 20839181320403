import React, { Component } from 'react'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import { connect } from 'react-redux'

import Title from '../components/page-title'
//import SubTitle from "../components/page-subtitle";
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import Image from '../components/page-image-new'
//import PageVideo from '../components/page-video';
import PageButtons from '../components/page-buttons'
import { theme } from '../util/style'
import { setLightboxForm } from '../actions'
import PageCtaFeature from '../components/page-cta-feature'
import PageVideo from '../components/page-video'
import PageOverview from '../components/page-overview'
import PageRuledList from '../components/page-ruled-list'

import PageHeroGallery from '../components/page-hero-gallery'

import Image1 from '../images/etc/happy-haus-helensvale-10.jpg'
import Image2 from '../images/etc/happy-haus-new-farm-14.jpg'
import Image3 from '../images/etc/happy-haus-holland-park-west-11.jpg'
import Image4 from '../images/etc/happy-haus-holland-park-west-14.jpg'
const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
  `https://happyhaus.com.au${Image3}`,
  `https://happyhaus.com.au${Image4}`,
]

const HeroImage1 = 'etc/happy-haus-helensvale-10.jpg'
const HeroImage2 = 'etc/happy-haus-new-farm-14.jpg'
const HeroImage3 = 'etc/happy-haus-holland-park-west-11.jpg'
const HeroImage4 = 'etc/happy-haus-holland-park-west-14.jpg'
const HeroImage5 = 'etc/happy-haus-new-farm-19.jpg'

const PortraitImage4 = 'etc/happy-haus-new-farm-1.jpg'

const PortraitImage7 = 'etc/happy-haus-new-farm-3.jpg'
const LandscapeImage1 = 'etc/happy-haus-wollumbin-11.jpg'
const LandscapeImage2 = 'etc/happy-haus-helensvale-10.jpg'
const LandscapeImage4 = 'etc/happy-haus-holland-park-west-13.jpg'
const LandscapeImage5 = 'etc/happy-haus-henson-8.jpg'
const LandscapeImage6 = 'etc/happy-haus-holland-park-west-14.jpg'
const LandscapeImage7 = 'etc/happy_haus_darlington_047.jpg'
const LandscapeImage8 = 'etc/happy-haus-henson-7.jpg'

const slides = [
  {
    image: HeroImage1,
  },
  {
    image: HeroImage2,
  },
  {
    image: HeroImage3,
  },
  {
    image: HeroImage4,
  },
  {
    image: HeroImage5,
  },
]

const mapDispatchToProps = dispatch => ({})

const Guide1Image = 'etc/happy-haus-helensvale-13.jpg'
const Guide2Image = 'resources/happyhaus-guide-custom-haus-cover.png'

class Page extends Component {
  constructor(props) {
    super(props)
    this._handleRequestClick = this._handleRequestClick.bind(this)
  }

  _handleRequestClick(e, form) {
    e.preventDefault()
    const { dispatch } = this.props

    switch (form) {
      case 'custom':
        dispatch(
          setLightboxForm({
            category: 'Build Guides New',
            title: 'Get your Custom Haus Guide',
            redirect: '/contact-thank-you',
          })
        )
        break

      case 'new':
        dispatch(
          setLightboxForm({
            category: 'Build Guide New',
            title: 'Get your free guide<br />to building your new home',
            redirect: '/build-guides-thank-you',
          })
        )
        break

      default:
        break
    }
  }

  render() {
    const CtaFeatures = [
      {
        title: '&nbsp;',
        image: Guide2Image,
        buttonLabel: 'Download our guide',
        bgColor: '#f1f1f1',
        bgColorHover: '#eeeeee',
        onClick: e => {
          this._handleRequestClick(e, 'custom')
        },
      },
    ]

    return (
      <>
        <SEO
          title="Custom Haus Guide"
          description="You and your family can have a home as individual as the lives
                you lead and the people you are. If you're looking to build a
                new custom home choose Happy Haus, and watch as we replace
                hassle with happiness throughout your home building journey."
          images={ogImages}
        />

        <Row>
          <Flex flexWrap="wrap" flexDirection={['column-reverse', 'row']}>
            <Box width={[1, 6 / 12]} px={2}>
              <Title>Considering an architectural commission?</Title>
            </Box>
            <Box width={[1, 6 / 12]} px={2}>
              <PageRuledList>
                <li>
                  <strong>Our guide has expert tips to help you</strong>
                </li>
                <li>Understand your block to maximise your build options</li>
                <li>
                  Plan for success with your vision, budget and chosen build
                  team
                </li>
                <li>Know what to consider when breifing your design team</li>
                <li>Be prepared for the construction process and more</li>
              </PageRuledList>
            </Box>
          </Flex>
        </Row>

        <PageCtaFeature features={CtaFeatures} />
        <Row>
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Box width={[1, 6 / 12]} px={2} pr={[0, 0]}>
              <Title>A home designed for you and family</Title>
            </Box>
            <Box width={[1, 6 / 12]} px={2} mr={[0, 0]} mb={100}>
              <Copy align="left">
                <p>
                  Building a new home is incredibly exciting. Some of our
                  clients choose to create custom homes to experience living in
                  a space designed to suit their lifestyle, specific site
                  characteristics, or to pursue an architectural vision.
                </p>
                <p>
                  To help you on your home building journey we've put together a
                  guide with expert tips on what to think about when considering
                  a architectural commission.
                </p>
                <a
                  onClick={e => {
                    e.preventDefault()
                    this._handleRequestClick(e, 'custom')
                  }}
                >
                  <strong>Download our FREE guide</strong>
                </a>
              </Copy>
            </Box>
          </Flex>
        </Row>

        <PageHeroGallery slides={slides} ratio={1 / 1.5} />

        {/* <Row>
          <Flex flexWrap="wrap" flexDirection="row">
            <Box width={[1, 1 / 2]} px={2} mr={[0, 0]}>
              <img src={PortraitImage7} alt="" />
            </Box>
            <Box width={[1, 1 / 2]} px={2} pr={[0, 100]}>
              <img src={LandscapeImage5} alt="" />
            </Box>
          </Flex>
        </Row> */}
        {/* 
        <Row noPad={true}>
          <Flex>
            <Box width={1} px={2}>
              <Image src={LandscapeImage1} ratio={1 / 1.5} />
            </Box>
          </Flex>
        </Row> */}
      </>
    )
  }
}

export default connect(mapDispatchToProps)(Page)
